.page-wrapper {
  &.page-login {

    .login-tabs {
        background-color: $color-gray;
        cursor: pointer;

        h2 {
          color: $color-white;
        }
      
        .active {
            background-color: $color-lightgray;
            h2 {
              color: $color-primary;
            }
        }
    }

    .login-box {
        background-color: $color-lightgray;
        color: $color-primary;
    }
  }
}
