@media screen and (max-width: 1500px) {
  // AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA
  .page-agenda {
    .day {
      font-size: 35px;
      line-height: 45px;
    }
  }
}

@media screen and (max-width: 1300px) {
  // AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA
  .page-agenda {
    .day {
      font-size: 30px;
      line-height: 40px;
    }
  }
}

@media screen and (max-width: 1100px) {
  // AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA
  .page-agenda {
    .day {
      font-size: 25px;
      line-height: 35px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .tww-container {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .people-image {
    display: none !important;
  }

  // HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL
  .page-hall {
    // Helpdesk
  }

  .top-header {
    .event-date {
      margin-bottom: 0.5rem;
    }
  }

  // LOGIN LOGIN LOGIN LOGIN LOGIN LOGIN LOGIN LOGIN
  .page-login {
    .man-image {
      display: none;

      max-width: 25rem;
      position: fixed;
      bottom: 4.6rem;
      left: 0%;
      z-index: -1;
    }
  }
  // PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY
  .page-plenary {
    .countdown-wrapper {
      .digitContainer {
        color: $color-white;
        font-size: 2.95rem;
        font-weight: bold;

        .digitLabel {
          font-size: 1rem;
          font-weight: normal;
        }
      }

      .countdown-image {
        width: 128px;
      }
    }
  }

  // AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA
  .page-agenda {
    .day {
      font-size: 20px;
      line-height: 30px;
    }
  }
}

@media screen and (max-width: 996px) {
  .tww-container {
    padding-top: 0.5rem;
    padding-bottom: 1rem;

    .tww-image-small {
      max-width: 14rem; //220px
    }
  }

  // HALL HALL HALL HALL HALL HALL HALL HALL HALL HALL
  .page-hall {
    .center-area {
    }
  }

  // AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA
  .page-agenda {
    .day {
      font-size: 18px;
      line-height: 28px;
    }
  }
}

@media screen and (max-width: 768px) {
  // LOGIN LOGIN LOGIN LOGIN LOGIN LOGIN LOGIN LOGIN
  .page-login {
    .man-image {
      display: block;

      max-width: 25rem;
      position: fixed;
      bottom: 4.6rem;
      left: 0%;
      z-index: -1;
    }
  }

  // NAVIGATION NAVIGATION NAVIGATION NAVIGATION NAVIGATION NAVIGATION NAVIGATION
  .navigation-wrapper {
    .navigation-container {
      .nav-logo {
        padding: 1rem 1rem 0 1rem !important;
      }
    }

    .mobile-header {
      padding-bottom: 0.2rem !important;

      .userprofile-name {
        font-size: 1.125rem;
      }
    }

    .nav-navigation {
      .nav-item-container {
        padding-bottom: 1.2rem !important;

        .nav-item-handle {
          font-size: 1.312rem;
        }
      }
    }
  }
  // INFODESK INFODESK INFODESK INFODESK

  .page-infodesk {
    .info-tab {
      .tab-title {
        font-size: $body-font-size-xxsmall;
        line-height: $body-font-size-xxsmall * 1.3;
      }
    }

    .info-audio {
      img {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  // AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA
  .page-agenda {
    .day {
      font-size: 15px;
      line-height: 25px;
    }
  }

  .page-hall {
    .center-area {
      .container {
        .hall-btn-container {
          margin-top: 3rem;

          .hall-btn {
            &.hall-btn {
              padding: 2rem 1rem;
            }
          }
        }
      }
    }
  }

  // POLL POLL POLL POLL POLL POLL POLL POLL POLL

  .poll-overlay-container {
    .poll-close-button {
      font-size: 1.5rem;
      top: 2rem;
      right: 0%;

      a,
      a:visited {
        line-height: 1rem;
        img {
          width: 1.5rem;
        }
      }
    }

    .poll-container {
      .box {
        padding: 0.3rem 0.3rem;
      }
      .poll-question {
        .text-wrapper {
          font-size: $body-font-size-xsmall;
        }
      }
      .poll-answer {
        .text-wrapper {
          font-size: $body-font-size-xxsmall;
          border: 2px solid $color-magenta;
          padding: 0.2rem 0.5rem;
        }
      }
    }
  }

  // AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA
  .page-agenda {
    .day {
      padding-top: 1rem;
      padding-bottom: 1rem;
      margin-top: 0;
      margin-bottom: 2rem;
      font-size: 14px;
      line-height: 24px;
    }

    .event {
      margin-bottom: 3rem;

      .event-category {
        min-height: 100px;

        img {
          width: 80px;
          height: 80px;
          bottom: 10px;
          right: 10px;
        }
      }
    }
  }
}

@media screen and (max-width: 575px) {
  // LOGIN LOGIN LOGIN LOGIN LOGIN LOGIN LOGIN LOGIN
  .page-login {
    .woman-image,
    .man_small-image {
      display: none;
    }
    .man-image {
      display: block;
      position: unset;
      max-width: 16rem;
      margin: 0 auto;
    }
  }

  // ITEMS LIST SCROLLABLE
  .items-list {
    width: 90vw;
    max-width: 90vw;
    overflow: auto;
    display: flex;

    .items-list-scroller {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      & > div {
        padding-top: 1rem;
        padding-bottom: 1.3rem !important;
        width: 8.5rem;
        position: relative;

        .arrow {
          transform: rotate(90deg);
          position: absolute;
          bottom: 0;
          left: 45%;
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }

  // PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY PLENARY
  .page-plenary {
    .themes-wrapper {
      .themes-list {
        width: 90vw;
        max-width: 90vw;
        overflow: auto;
        display: flex;

        .themes-list-scroller {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;

          .theme-toggle {
            padding-top: 1rem;
            padding-bottom: 1.3rem !important;
            width: 8.5rem;
          }
        }
      }
    }

    .plenary-agenda {
      position: fixed;
      top: 4rem;
      margin: 0 5%;
      height: auto;
      max-height: unset;
      width: 90%;
      box-shadow: 0px 0px 40px 50px rgba(0, 0, 0, 0.65);

      .plenary-agenda-list {
        overflow-y: scroll;
        max-height: unset;
      }
    }

    .man-image {
      display: block;
      position: unset;
      max-width: 16rem;
      margin: 0 auto;
    }

    .countdown-wrapper {
      .digitContainer {
        color: $color-white;
        font-size: 1.75rem;
        font-weight: bold;

        .digitLabel {
          font-size: 1rem;
          font-weight: normal;
        }
      }

      .countdown-image {
        width: 128px;
      }
    }
  }
}
