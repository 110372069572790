// AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA
// AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA
// AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA AGENDA

.page-agenda {
  .day {
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
    font-weight: 300;
    color: #cc0000;
    cursor: pointer;
    margin-top: 2rem;
    margin-bottom: 3rem;
    border-bottom: 10px solid #ccc;

    &.selected {
      border-bottom: 10px solid #cc0000;
      font-weight: 700;
      color: $color-text;
    }
  }

  .event {
    border-bottom: 1px solid #ddd;
    margin-bottom: 2rem;

    .event-category {
      font-size: 20px;
      font-weight: 900;
      line-height: 24px;
      color: #fff;
      background-color: red;
      min-height: 250px;
      position: relative;

      span {
        font-size: 15px;
        font-weight: 400;
        color: $color-text;
      }

      img {
        width: 120px;
        height: 120px;
        position: absolute;
        bottom: 30px;
        right: 30px;
      }

      &.cat1 {
        background-color: #d152ff;
      }

      &.cat2 {
        background-color: #cead2e;
      }

      &.cat3 {
        background-color: #12dc7a;
      }

      &.cat4 {
        background-color: #fd5757;
      }

      &.cat5 {
        background-color: #dd393c;
      }

      &.cat6 {
        background-color: #12c2dc;
      }

      &.cat7 {
        background-color: #3da06d;
      }

      &.cat8 {
        background-color: #fdbd1c;
      }

      &.cat9 {
        background-color: #3085ef;
      }
    }

    .event-title {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;

      span {
        font-size: 15px;
        font-weight: 400;
      }
    }

    .event-speaker {
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;

      span {
        font-size: 15px;
        font-weight: 400;
      }
    }

    .event-organization {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;

      span {
        font-size: 15px;
        font-weight: 400;
      }
    }
  }
}
