.page-live {
  .center-area {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    align-content: stretch;
    justify-content: flex-start;

    .container-fluid {
      flex-grow: 1;
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      align-content: stretch;
      justify-content: flex-start;

      .row {
        flex-grow: 1;
        flex-direction: row;

        .live-agenda-wrapper {
          display: flex;
          flex-flow: column nowrap;
          align-items: stretch;
          align-content: stretch;
          justify-content: flex-start;

          .live-agenda {
            flex-grow: 1;
            border: 1px solid red;
            overflow: scroll;
            border: 1px solid #ccc;
            border-radius: 4px;
            margin-bottom: 1rem;

            display: flex;
            flex-flow: column nowrap;
            align-items: stretch;
            align-content: stretch;
            justify-content: flex-start;

            .live-agenda-title {
              font-size: 1.2rem;
              font-weight: bold;
              padding: 0.5rem 1rem;
              background-color: #eee;
            }

            .live-agenda-events {
              overflow-y: scroll;
              height: 0;
              flex-grow: 1;
              margin-right: 2px;

              .event {
                padding: 0.5rem 1rem;
                font-size: 0.9rem;

                &.current {
                  background-color: #f3f3f3;
                }

                span {
                  color: $color-primary;
                  font-weight: 400;
                }

                p {
                  padding: 0;
                  margin: 0;
                  font-size: 0.8rem;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }
  }
}
