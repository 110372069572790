.Live {
    width: 100%;
    height: 100%;
    display: grid;
    row-gap: 35px;
    padding: 16px;
    place-items: center;
  }
  
  /* @media screen and (min-width: 768px) {
    .Live {
      padding: 35px 100px;
    }
  } */
  
  .Live-contentPlaceholder {
    height: 200px;
    width: 100%;
    max-width: 1920px;
    background-color: #ccc;
    border-radius: 8px;
  }
  