.page-hall {
  .center-area {
    margin-top: 0!important;
    .container {
      .hall-btn-container {
        margin-top: 5rem;

        .hall-btn {
          font-size: 40px;
          line-height: 50px;
          font-weight: 700;
          color: #fff;
          cursor: pointer;

          &.hall-btn {
            background-color: $color-primary;
            padding: 7rem 1rem; 

            &.hall-btn-primary {
                background-color: #ca0813;
            }

            &.hall-btn-secondary {
                background-color: #a4a4a4;
            }
          }
        }
      }
    }
  }
}
